
<template>
  <div style="overflow: auto; height: calc(100vh - 116px)">
    <a-spin :tip="loadingTip" :spinning="isloading" style="margin-top: 24px">
      <a-form-model
        ref="websiteForm"
        :model="info"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="网站名称">
          <a-input placeholder="请输入" v-model="info.name"></a-input>
        </a-form-model-item>
        <a-form-model-item label="客服电话1">
          <a-input placeholder="请输入" v-model="info.telephone1"></a-input>
        </a-form-model-item>
        <a-form-model-item label="客服电话2">
          <a-input placeholder="请输入" v-model="info.telephone2"></a-input>
        </a-form-model-item>
        <a-form-model-item label="QQ客服1">
          <a-input placeholder="请输入" v-model="info.qqNumber1"></a-input>
        </a-form-model-item>
        <a-form-model-item label="QQ客服2">
          <a-input placeholder="请输入" v-model="info.qqNumber2"></a-input>
        </a-form-model-item>
        <a-form-model-item label="服务时间">
          <a-input placeholder="请输入" v-model="info.serverTime"></a-input>
        </a-form-model-item>
        <a-form-model-item label="网站标题">
          <a-input placeholder="请输入" v-model="info.seoTitle"></a-input>
        </a-form-model-item>
        <a-form-model-item label="关键字">
          <a-input placeholder="请输入" v-model="info.seoKeywords"></a-input>
        </a-form-model-item>
        <a-form-model-item label="网站描述">
          <a-input
            type="textarea"
            placeholder="请输入"
            v-model="info.seoDescription"
          ></a-input>
        </a-form-model-item>
        <a-row>
          <a-col :span="8">
            <a-form-model-item
              label="网站logo"
              :label-col="{ span: 12 }"
              :wrapper-col="{ span: 8 }"
            >
              <a-upload
                accept="image/png,image/jpeg"
                :action="$api.file.uploadFile"
                listType="picture-card"
                :fileList="logoFileList"
                :beforeUpload="beforeUpload"
                @preview="handlePreview"
                @change="handleChange('logo', $event)"
              >
                <div v-if="logoFileList.length < 1">
                  <a-icon type="plus" />
                  <div class="ant-upload-text">Upload</div>
                </div>
              </a-upload>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="公众号图片" :wrapper-col="{ span: 8 }">
              <a-upload
                accept="image/png,image/jpeg"
                :action="$api.file.uploadFile"
                listType="picture-card"
                :fileList="fileList"
                :beforeUpload="beforeUpload"
                @preview="handlePreview"
                @change="handleChange('other', $event)"
              >
                <div v-if="fileList.length < 1">
                  <a-icon type="plus" />
                  <div class="ant-upload-text">Upload</div>
                </div>
              </a-upload>
            </a-form-model-item>
          </a-col>
          <a-modal
            :visible="previewVisible"
            :footer="null"
            @cancel="handleCancel"
          >
            <img alt="example" style="width: 100%" :src="previewImage" />
          </a-modal>
        </a-row>
        <a-form-model-item label="底部版权">
          <vue-ueditor-wrap
            v-model="info.copyRight"
            :config="myConfig"
          ></vue-ueditor-wrap>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 10, offset: 4 }">
          <pxkeji-btn name="保存" type="primary" @click="handleSubmit" />
        </a-form-model-item>
      </a-form-model>
    </a-spin>
  </div>
</template>

<script>
import VueUeditorWrap from "vue-ueditor-wrap";
let _this;
export default {
  data() {
    return {
      isloading: false,
      loadingTip: "加载中...",
      labelCol: { span: 4 },
      wrapperCol: { xxl: 10, xl: 14 },
      info: {},
      rules: {},
      fileList: [],
      logoFileList: [],
      previewVisible: false,
      previewImage: "",
      myConfig: {
        autoHeightEnabled: false,
        initialFrameHeight: 150,
        wordCount: true,
        maximumWords: 100000,
        initialFrameWidth: "100%",
        serverUrl: this.$api.file.uploadUpFile,
        UEDITOR_HOME_URL: "/UEditor/",
        toolbars: this.$commen.editorConfig.toolbars,
      },
    };
  },
  components: {
    VueUeditorWrap,
  },
  mounted() {
    _this = this;
    _this.getInfo();
  },
  methods: {
    getInfo() {
      _this.isloading = true;
      _this.$api.systemSetting
        .getPortalSetting()
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            if (res.data) {
              _this.info = res.data;
              _this.fileList = [];
              _this.logoFileList = [];
              if (res.data.url) {
                let pics = res.data.url.split(",");
                for (let i = 0; i < pics.length; i++) {
                  _this.logoFileList.push({
                    uid: i,
                    name: "图片" + (i + 1) + ".png",
                    status: "done",
                    url: pics[i],
                  });
                }
              }
              if (res.data.loyooUrl) {
                let pics = res.data.loyooUrl.split(",");
                for (let i = 0; i < pics.length; i++) {
                  _this.fileList.push({
                    uid: i,
                    name: "图片" + (i + 1) + ".png",
                    status: "done",
                    url: pics[i],
                  });
                }
              }
            }
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    handleCancel() {
      _this.previewVisible = false;
    },
    handlePreview(file) {
      _this.previewImage = file.url;
      _this.previewVisible = true;
    },
    beforeUpload(file) {
      let isJPGPNG = false;
      if (file.type === "image/jpeg" || file.type === "image/png") {
        isJPGPNG = true;
      } else {
        isJPGPNG = false;
        _this.$message.error("只能上传jpg/png格式的文件!");
      }
      let isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        _this.$message.error("文件不能超过2MB!");
      }
      return isJPGPNG && isLt2M;
    },
    handleChange(type, info) {
      if (type == "logo") {
        _this.logoFileList = info.fileList;
      } else {
        _this.fileList = info.fileList;
      }
      if (info.file.status == "done") {
        if (info.file.response.errorCode == "0000") {
          if (type == "logo") {
            _this.logoFileList[_this.logoFileList.length - 1].url =
              info.file.response.data.path;
          } else {
            _this.fileList[_this.fileList.length - 1].url =
              info.file.response.data.path;
          }
        } else {
          _this.$message.error("上传失败");
        }
      } else if (info.file.status == "error") {
        _this.$message.error("上传失败");
        if (type == "logo") {
          _this.logoFileList.splice(_this.logoFileList.length - 1, 1);
        } else {
          _this.fileList.splice(_this.fileList.length - 1, 1);
        }
      }
    },
    handleSubmit() {
      _this.$refs.websiteForm.validate((valid) => {
        if (valid) {
          _this.isloading = true;
          _this.loadingTip = "保存中...";
          let websiteInfo = { ..._this.info };
          websiteInfo.loyooUrl = _this.fileList.map((item) => item.url).join();
          websiteInfo.url = _this.logoFileList.map((item) => item.url).join();
          _this.$api.systemSetting
            .setPortalSetting(websiteInfo)
            .then((res) => {
              if (res.errorCode == _this.$msg.responseCode.success) {
                _this.$message.success(res.errorMsg);
                _this.getInfo();
              } else {
                _this.$$message.error(res.errorMsg);
              }
              _this.isloading = false;
            })
            .catch(() => {
              _this.isloading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style>
</style>